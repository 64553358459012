import Header from "./Header";
import Footer from "./Footer";
import { Link } from "react-router-dom";

function Contact() {
  return (
    <>
      <Header />

      <div
        id="carouselExampleAutoplaying"
        class="carousel slide carousel-fade"
        data-bs-ride="carousel"
      >
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img
              src="assets/contact.jpg"
              class="d-block w-100 vh-100 object-fit-cover"
              alt="..."
            />
          </div>
        </div>
      </div>

      <section className="py-5 bg-white">
        <div className="container py-5">
          <div className="row py-5">
            <div className="col-lg-6 col-12">
              <div className="pe-lg-5 pe-md-4 pe-0 mt-5">
                <h2 className="fw-light display-5">
                  Please feel free to
                  <br />
                  contact us
                </h2>
                <p className="mt-4">
                  We're Where Creativity Meets Strategy with a Dazzling Smile!
                </p>
                <div className="mt-5">
                  <p>Mumbai</p>
                  <p>
                    <i className="bi bi-geo-alt me-2"></i>
                    Cranberry Digital Media
                  </p>
                  <p>
                    Wework Enam Sambhav, C-20, G Block Rd, G Block. BKC, Bandra
                    East,Mumbai - 400051
                  </p>
                  <p className="mt-4">
                    <i className="bi bi-envelope me-2"></i> Email:
                    hello@cranberry.media
                  </p>
                </div>
              </div>

              <form className="mt-5">
                <input
                  className="form-control py-2 mb-2 border-secondary ff-arial"
                  type="text"
                  name="name"
                  placeholder="Name"
                  required
                />
                <input
                  className="form-control py-2 mb-2 border-secondary ff-arial"
                  type="email"
                  name="email"
                  placeholder="Email Address"
                  required
                />
                <input
                  className="form-control py-2 mb-2 border-secondary ff-arial"
                  type="text"
                  name="phone"
                  placeholder="Phone No"
                  required
                />
                <textarea
                  className="form-control py-2 mb-2 border-secondary ff-arial"
                  type="text"
                  name="message"
                  rows="4"
                  placeholder="Message"
                  required
                ></textarea>
                <Link className="btn btn-dark px-5 py-2 rounded-pill mt-4 ff-arial">
                  Send Message
                </Link>
              </form>
            </div>
            <div className="col-lg-6 col-12">
              <img src="assets/10.webp" className="w-100 mt-5" />
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default Contact;
