import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

function Header() {
  const [isScrolled, setIsScrolled] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 8 * 16) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    AOS.init({});
  }, []);

  return (
    <>
      <div className={isScrolled ? "fixed-top bg-black" : "fixed-top"}>
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <div className="py-2">
              <Link to="/menu">
                <img
                  src="assets/menu.png"
                  width="35"
                  type="button"
                  className="me-3"
                />
              </Link>
              <a href="https://cranberry.media/">
                <img src="assets/white-logo.png" width="180" />
              </a>
            </div>
            <div>
              <Link to="/portfolio">
                <img
                  src="assets/grid.png"
                  width="32"
                  type="button"
                  className="me-3"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
