import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

function Menu() {
  const [isScrolled, setIsScrolled] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 8 * 16) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
      mirror: false,
    });
  }, []);

  return (
    <>
      <div className="container pt-1">
        <div>
          <a href="https://cranberry.media/">
            <img
              alt="close"
              src="assets/close.png"
              width="25"
              className="me-3"
            />
          </a>

          <img
            alt="canvas"
            src="assets/logo.png"
            width="180"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasRight"
            aria-controls="offcanvasRight"
          />
        </div>
        <div className="d-flex justify-content-center">
          <div className="text-center mt-5 pt-4 col-lg-8 col-12">
            <a
              href="https://cranberry.media/"
              className="text-black text-decoration-none"
            >
              <h2
                data-aos="fade-left"
                data-aos-delay="100"
                className="my-title fw-bold m-0 cursor-pointer text-center menu-item-visible"
              >
                Home &nbsp; 
              </h2>
            </a>
            <h2
              data-aos="fade-left"
              data-aos-delay="200"
              onClick={() => navigate("/about")}
              className="my-title fw-bold m-0 cursor-pointer text-center menu-item-visible"
            >
              About Us &nbsp; 
            </h2>
            <h2
              data-aos="fade-left"
              data-aos-delay="300"
              onClick={() => navigate("/portfolio")}
              className="my-title fw-bold m-0 cursor-pointer text-center menu-item-visible"
            >
              Our Portfolio &nbsp; 
            </h2>
            <h2
              data-aos="fade-left"
              data-aos-delay="400"
              onClick={() => navigate("/services")}
              className="my-title fw-bold m-0 cursor-pointer text-center menu-item-visible"
            >
              What We Offer &nbsp;  
            </h2>
            <h2
              data-aos="fade-left"
              data-aos-delay="400"
              onClick={() => navigate("/contact")}
              className="my-title fw-bold m-0 cursor-pointer text-center menu-item-visible"
            >
              Contact Us &nbsp; 
            </h2>
          </div>
        </div>
      </div>
    </>
  );
}

export default Menu;